<template>
    <div>
        <div v-if="authUserPermission['abbreviation-view']">
            <base-header class="pb-6">
                <div class="row align-items-center py-4">
                    <div class="col-lg-6 col-7">
                        <h6 class="h2 text-white d-inline-block mb-0"></h6>
                    </div>      
                </div>
            </base-header>
            <div class="container-fluid mt--6">
                <div class="card mb-4">
                    <div class="card-header mt--4 mb--4">
                        <div class="row">
                            <div class="col-7">
                                <h3 class="mb-0">{{ tt('characteristic_value') }}</h3>
                            </div>
                            <div class="col-5">
                                <div class="form-group">
                                    <input type="text" class="form-control form-control-sm text-center border-radius-20" v-model="table.search" :placeholder="tt('characteristic_value')" v-on:keyup="filter"/>                            
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <el-table height="350px" class="table-responsive table-flush" header-row-class-name="thead-light" :data="table.data" v-if="!onLoad">
                            <el-table-column :label="tt('inc')" :prop="tt('inc')" min-width="122px" sortable>
                                <template v-slot="{row}">
                                    {{row.inc}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('characteristic')" :prop="tt('characteristic')" min-width="140px" sortable>
                                <template v-slot="{row}">
                                    {{row.characteristic}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('value')" :prop="tt('value')" min-width="140px" sortable>
                                <template v-slot="{row}">
                                    {{row.value}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('abbrev')" :prop="tt('abbrev')" min-width="140px" sortable>
                                <template v-slot="{row}">
                                    {{row.abbreviation}}
                                </template>
                            </el-table-column>
                            <el-table-column :label="tt('status')" :prop="tt('status')" min-width="140px" sortable>
                                <template v-slot="{row}">
                                    {{(row.approved == 1) ? 'Approved' : 'Not Approved'}}
                                </template>
                            </el-table-column>
                            <el-table-column prop="action" width="100">
                                <template v-slot="{row}">
                                    <el-dropdown trigger="click" class="dropdown" @command="handleTableAction" :hide-on-click="true" v-if="authUserPermission['abbreviation-update']">
                                        <span class="btn btn-sm btn-icon-only text-light">
                                            <i class="fas fa-ellipsis-v mt-2"></i>
                                        </span>
                                        <el-dropdown-menu class="dropdown-menu dropdown-menu-arrow show" slot="dropdown">
                                            <el-dropdown-item :command="{action:'edit', data:row}" v-if="authUserPermission['abbreviation-update']">{{ tt('edit') }}</el-dropdown-item>
                                        </el-dropdown-menu>
                                    </el-dropdown>
                                </template>
                            </el-table-column>
                        </el-table>
                        <page-loading v-else/>
                    </div>      
                    <div class="card-footer pb-0 ">   
                        <span class="float-left">
                            <base-pagination :page-count="table.page.last_page" v-model="table.page.current_page" @click.native="changePage(table.page.current_page)" size="sm"></base-pagination>
                        </span>                             
                        <span class="float-right">
                            {{ tt('page_info', {size: (table.page.current_page - 1) * table.page.per_page + table.data.length, total: table.total}) }}
                        </span>
                    </div>
                </div>
            </div>
            
            <!-- MODAL ADD CHARACTERISTIC VALUE -->
            <validation-observer v-slot="{invalid}">
                <modal :show.sync="formCharValue.show">
                    <template slot="header">
                        <h5 class="modal-title">{{formCharValue.title}}</h5>
                    </template>
                    <div>
                        <!-- <base-alert type="danger" v-if="errorMessage">
                            <p><strong>{{ errorMessage.message }}</strong></p>
                            <div v-if="errorMessage.data.length != 0">
                                <span v-for="message in errorMessage.data">
                                    {{ message[0] }}<br>
                                </span>
                            </div>
                        </base-alert> -->
                        <div>
                            <label class="form-control-label">{{ tt('current_value') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('current_value')" :placeholder="tt('current_value')" v-model="characteristicValue.current_value" readonly></base-input>

                            <label class="form-control-label">{{ tt('update_to') }} <span class="text-danger">*</span></label>
                            <base-input :name="tt('update_to')" :placeholder="tt('update_to')" v-model="characteristicValue.characteristic_value" rules="required|max:95"></base-input>
                        </div>

                        <label class="form-control-label">{{ tt('abbreviation') }}</label>
                        <base-input :name="tt('abbreviation_to')" :placeholder="tt('abbreviation')" v-model="characteristicValue.abbreviation"></base-input>

                        <label class="form-control-label">{{ tt('approval') }} <span class="text-danger">*</span></label>
                        <base-input :name="tt('approval')" rules="required">
                            <el-select class="select-danger" v-model="characteristicValue.approved" :placeholder="tt('choose_approval')">
                                <el-option class="select-danger" value="0" label="Not Approved" key="Not Approved"></el-option>
                                <el-option class="select-danger" value="1" label="Approved" key="Approved"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                    <template slot="footer">
                        <base-button type="secondary" @click="formCharValue.show = false">{{ tt('close') }}</base-button>
                        <base-button type="primary" v-on:click="saveCharValue" :disabled="btnSaveCharValue.onLoading || invalid">
                            <span v-if="btnSaveCharValue.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
                            <span v-else>
                                <span v-if="formCharValue.add">{{ tt('add') }}</span>
                                <span v-else>{{ tt('edit') }}</span>
                            </span>
                        </base-button>
                    </template>
                </modal>
            </validation-observer>
        </div>
        <noaccess v-else/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import characteristicValue from '@/services/dictionary/characteristicValue.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';

    export default {        
        data() {
            return {  
                onLoad: true,      
                loadTimeout: null,  
                btnSaveCharValue: {
                    onLoading: false
                },                   
                formCharValue: {
                    add: true,
                    title: this.tt('add_new_mcr'),
                    show: false,
                },
                table: {
                    search: '',                    
                    total: 0,
                    data: [],
                    page: {
                        current_page: 1,
                        per_page: 100,
                    },
                },     
                errorMessage: null,   
                characteristicValue: {},
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.get()
        },
        methods: {
            get() { 
                let context = this;               
                Api(context, characteristicValue.get(this.table.page.current_page, {search: this.table.search})).onSuccess(function(response) {    
                    context.table.total = response.data.data.total;
                    context.table.data  = response.data.data.data;
                    context.table.page  = response.data.data;  
                    context.onLoad      = false;          
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.table.data = [];
                        context.table.total = 0;
                        context.onLoad = false;
                    }
                })
                .call()
            },
            filter() {
                this.table.page.current_page = 1;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 1000);
            },
            handleTableAction(command) {
                switch (command.action) {
                    case 'edit':
                            this.editCharValue(command.data);
                        break;
                    default:
                        break;
                }
            },
            editCharValue(data) {
                let context = this;
                context.characteristicValue = {
                    id: data.rcinv_id,
                    cin_code: data.cin_code,
                    current_value: data.value,
                    characteristic_value: data.value,
                    abbreviation: data.abbreviation,
                    approved: String(data.approved),
                };                
                context.formCharValue.add = false;
                context.formCharValue.title = context.tt('edit_characteristic_value');
                context.formCharValue.show = true;                          
            },
            saveCharValue() {    
                let context = this;           
                let api = null;       
                context.btnSaveCharValue.onLoading = true;

                api = Api(context, characteristicItemName.update_char_value(context.characteristicValue.id, context.characteristicValue));
                api.onSuccess(function(response) {
                    context.get();
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.formCharValue.show = false;
                    context.characteristicValue = {
                        current_value: '',
                        approved: '0',
                    }
                }).onError(function(error) {                    
                    context.errorMessage = error.response.data;
                    context.$notify({
                        message:error.response.data.message,
                        type: "danger",
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                }).onFinish(function() {
                    context.btnSaveCharValue.onLoading = false;   
                })
                .call();
            },
            changePage(page) {
                let context = this;
                context.onLoad = true;

                clearTimeout(this.loadTimeout);
                this.loadTimeout = setTimeout(() => {
                    this.get()
                }, 100);
            },
        }   
    };
</script>
<style></style>
